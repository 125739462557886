let targets = document.querySelectorAll(".jsActionRadioButtonsTarget");

if (targets && targets.length > 0) {
    targets.forEach(target => {
        var id = target.id;
        var buttons = target.dataset.buttons;

        if (!id || !buttons) return;

        buttons = buttons.replace(/\s+/, ' ').split(' ');

        buttons.forEach(button => {
            button = document.getElementById(button);

            if (!button) return;

            button.addEventListener("click", () => {
                var value = button.dataset.value;

                if (!value) return;

                target.value = value;
            });
        });

        target.addEventListener("input", () => {
            buttons.forEach(button => {
                button = document.getElementById(button);

                if (!button || !button.dataset.value) return;

                button.checked = target.value === button.dataset.value ? true : false;
            });
        });
    });
}
