let drawer = document.getElementById('drawer');
let header = document.getElementById('header');
let html   = document.documentElement;
let toggle = document.getElementById("toggle");

toggle.addEventListener("click", (event) => {
    html.classList.toggle("h-OvH");
    html.classList.toggle("h-PosF");
    header.classList.toggle("isDrawerOpen");
    drawer.classList.toggle("isActive");
    toggle.classList.toggle("isActive");
}, false);
