const modals = document.querySelectorAll('.jsModal');



const get = (ids) => {
    let result = [];

    if (ids.includes(' ') === true) {
        const splitted = ids.split(' ');
        splitted.forEach(id => {
            const element = document.getElementById(id);
            if (element !== null) {
                result.push(element);
            }
        });
    } else {
        const element = document.getElementById(ids);
        if (element !== null) {
            result.push(element);
        }
    }

    return result;
};

const open = (modal, element) => {
    modal.classList.add("isOpen");
    document.documentElement.classList.add("h-OvH");
};

const close = (modal, element) => {
    modal.classList.remove("isOpen");
    document.documentElement.classList.add("h-OvH");
};



if (modals && modals.length > 0) {
    modals.forEach(modal => {
        let openers = modal.getAttribute('data-openers');
        let closers = modal.getAttribute('data-closers');

        openers = get(openers);
        closers = get(closers);

        openers.forEach(element => element.addEventListener('click', () => { open(modal, element) }, false));

        closers.forEach(element => element.addEventListener('click', () => { close(modal, element) }, false));
    });
}
