let scrolltop = document.querySelector('#scrolltop');

if (scrolltop !== null) {
    scrolltop.addEventListener('click', (event) => {
        document.documentElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }, false);
}
