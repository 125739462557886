var commentReplies      = document.querySelectorAll(".c-CommentMeta-reply");
var commentSubmit       = document.querySelector("#commentFormSubmit");
var commentCancelHidden = document.querySelector("#commentFormCancelHidden");
var commentCancel       = document.querySelector("#commentFormCancel");
var commentAddressee    = document.querySelector("#comment_parent");

if (commentReplies && commentCancel) {
    commentReplies.forEach((reply) => {
        reply.addEventListener("click", function() {
            var id   = this.dataset.commentFormReplyId;
            var text = this.dataset.commentFormReplyText;

            if (id && text) {
                commentCancelHidden.hidden = false;
                commentAddressee.value     = id;
                commentSubmit.textContent  = text;
            }
        });
    });

    commentCancel.addEventListener("click", function() {
        commentCancelHidden.hidden = true;
        commentAddressee.value     = 0;
        commentSubmit.textContent  = "Kommentar abschicken";
    });
}
