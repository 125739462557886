const bands = document.querySelectorAll(".jsStickyBand");

const sentinel = (band) => {
    const random = Math.round(Math.random() * 1000000);

    band.setAttribute('id', `stickyBand${random}`);
    band.setAttribute('data-sentinel', `stickySentinel${random}`);

    let element = document.createElement('div');
    element.classList.add('jsStickySentinel');
    element.setAttribute('id', `stickySentinel${random}`);
    element.setAttribute('data-band', `stickyBand${random}`);
    element.style.left = '0';
    element.style.position = 'absolute';
    element.style.right = '0';
    element.style.visibility = 'hidden';

    band.insertAdjacentElement('beforeBegin', element);

    return element;
}

const observer = new IntersectionObserver(
    (entries) => {
        for (const entry of entries) {
            const target = document.querySelector('#' + entry.target.getAttribute('data-band'));
            const action = target.querySelector(".jsAction");
            const sentinel = entry.boundingClientRect;
            const container = entry.rootBounds;

            // Scroll down. Sticky turned on.
            if (sentinel.bottom < container.top) {
                action.classList.add('isSticky');
            }

            // Scroll up. Sticky turned off.
            if ( sentinel.bottom >= container.top && sentinel.bottom < container.bottom ) {
                action.classList.remove('isSticky');
            }
        }
    }, {
        threshold: [0]
    }
);

if (window.matchMedia('(min-width: 64em)').matches) {
    bands.forEach(band => {
        const style = window.getComputedStyle(band);
        const position = style.getPropertyValue('position');
        if (position === 'sticky') {
            let element = sentinel(band);
            observer.observe(element);
        }
    });
}
