const forms = document.querySelectorAll('.jsForm');

const submit = (form) => {
    const inputs = form.querySelectorAll("input");
    const action = form.getAttribute("action");

    let response = form.getAttribute("data-response");
    if (response) {
        response = document.getElementById(response);
    } else {
        response = form.querySelector('.jsFormResponse');
    }

    const FD  = new FormData();
    const XHR = new XMLHttpRequest();

    XHR.open("POST", action, true);

    inputs.forEach(input => {
        FD.append(input.name, input.value);
    });

    XHR.send(FD);

    if (response !== null) response.textContent = 'Senden...';

    XHR.addEventListener('error', function() {
        if (response !== null) response.textContent = 'Error.';
        form.reset();
    });

    XHR.addEventListener('loadend', function() {
        if (response !== null) response.textContent = XHR.status === 200 ? 'Geschickt.' : 'Error.'
        form.reset();
    });
}

if (forms && forms.length > 0) {
    forms.forEach(form => {
        form.addEventListener('submit', (event) => {
            event.preventDefault();
            submit(form);
        });
    });
}
